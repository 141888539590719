<template>
	<div class="trends">
		<div class="title" :style="`color:${groupDetails && groupDetails.group && groupDetails.group.color}`">{{ groupDetails.group.name }}</div>
		<score size="big" :score="myScore.score" :numCorrect="myScore.numCorrect" :numChoices="myScore.numChoices" :percentage="myScore.percentage"></score>

		<!-- Score graph -->
		<score-graph :scoreLines="userScores"></score-graph>

		<!-- Ranked User List -->
		<div class="resultHeader">Clever Score Standings</div>
		<table width="100%">
			<tr v-for="(user, idx) in groupDetails.users" :key="`user-${user.id}`">
				<td><b>{{ idx + 1 }}.</b></td>
				<td><span :style="`color:${user.color}`"><b>{{ user.userName }}</b></span></td>
				<td><img v-if="user.bestActor && user.bestActor.imageURL" style="float: left; height: 18px;background-color: #efeff5; padding: 1px; margin: 0px 4px 0px 2px; border-radius: 3px;"
						class="actorImage" :src="user.bestActor.imageURL"></td>
				<td>
					<template v-if="user.bestActor">{{ user.bestActor.useThe ? 'the' : '' }} {{ user.bestActor.name }}
						&nbsp;<spark-line v-if="user.bestActor.numChoices > 1" class="sparkline" :scoreLines="[{color: '#0095e6', scores: user.bestActor.scores}]"></spark-line>&nbsp;
					</template>
				</td>
				<td>
					<div class="right" :class="{'good ': user.score.score >= 0, 'bad ': user.score.score < 0}">{{ user.score.score }}</div>
				</td>
			</tr>
		</table>

		<!-- Influencers -->
		<template v-if="influencers && influencers.length > 0">
			<div class="resultHeader">Best Influencers</div>
			<table width="100%">
				<tr v-for="(inf,idx) in influencers" :key="`inf-${idx}`">
					<td> <span :style="`color:${inf.color}`">{{ inf.userName }}</span>
						{{ inf.bestInfluencer.name.substr(0,35) }}{{ inf.bestInfluencer.name.length > 35 ? '...':'' }}
					</td>
					<td>
						&nbsp;<spark-line v-if="inf.bestInfluencer.numChoices > 1" class="sparkline" :scoreLines="[{color: '#0095e6', scores: inf.bestInfluencer.scores}]"></spark-line>&nbsp;
					</td>
					<td>
						<div class="right" :class="{'good ': inf.bestInfluencer.score >= 0, 'bad ': inf.bestInfluencer.score < 0}">{{ inf.bestInfluencer.score }}</div>
					</td>
				</tr>
			</table>
		</template>

		<!-- Chat -->
		<div class="resultHeader">Discussion</div>
		<input
			ref="newChat"
			v-model="chatText"
			type="text"
			class="inputChat"
			placeholder="Talk the talk..."
		/><br />
		<button class="goButton" @click="addChat()">Talk the Talk</button>
		<div v-for="chat in groupDetails.chats" :key="chat.id" class='previousChats'><b style="font-size:12px">{{ tinyDate(chat.createdAt) }}</b>
			<b :style="`color:${chat.color}`">{{ chat.userName }}</b> - {{ chat.talk }}
		</div>
		<u style="cursor:pointer" @click="goToChat()" v-if="groupDetails.chats.length >= 10">Show More</u>
		
		<div class="bottom-spacer" />

</div>
</template>

<script>
import ScoreGraph from "@/components/ScoreGraph";
import Score from "@/components/Score";
import SparkLine from "@/components/SparkLine";

export default {
	name: "Group",
	components: {
		Score,
		ScoreGraph,
		SparkLine
	},
	data() {
		return {
			chatText: "",
			myScore: {},
			userScores: [],
			influencers: [],
			groupDetails: {
				group: {name: ""},
				chats: []
			}
		};
	},
	created() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.groupDetails = JSON.parse(localStorage.getItem(`group-${this.$route.params.id}-details`));
			this.groupDetails = await this.$services.get(`groups/${this.$route.params.id}/details`);
			this.myScore = (this.groupDetails.users.find(u => u.id == this.$services.me.id)).score;
			this.userScores = this.groupDetails.users.filter(u => u.score.numChoices > 0);
			localStorage.setItem(`group-${this.$route.params.id}-details`, JSON.stringify(this.groupDetails));
			// console.log("Group details", this.groupDetails)
			this.influencers = this.groupDetails.users.filter(u => u.bestInfluencer && u.bestInfluencer.score > 0).map(u => {
				const inf = {bestInfluencer: u.bestInfluencer, color: u.color, userName: u.userName};
				return inf;
			});
			this.influencers.sort((a, b) => +a.bestInfluencer.score < +b.bestInfluencer.score ? 1 : -1);
		},
		addChat: async function () {
			this.chatText = this.chatText.trim();
			if (this.chatText.length > 0) {
				this.groupDetails.chats = await this.$services.createOne(`groups/${this.$route.params.id}/chat`, {text: this.chatText});
				this.chatText = "";
			}
		},
		goToChat: function () {
			this.$router.push(`/group/${this.$route.params.id}/chat`)
		},
		tinyDate: function (dt) {
			const date = new Date(dt);
			return `${date.getMonth() + 1}/${date.getDate()} `;
		}
	}
}

</script>

<style scoped>
td {
	vertical-align: top;
}

.best-image {
	height: 40px;
}

.inputChat {
	margin: 2px 2vw 0px 2vw;
	font-size: 24px;
	padding: 3px;
	border-radius: 5px;
	width: 94vw;
	color: black;
}

.previousChats {
	font-size: 14px;
	margin: 5px;
}

.resultHeader {
	font-size: 22px;
	background: #444444;
	margin: 5px 0px 2px 0px;
	border-top: 1px solid yellow;
	color: white;
	padding: 2px;
}
</style>
