<template>
	<span ref="canvas" class="sparkline"></span>
</template>

<script>
import * as d3 from 'd3';

export default {
	name: "SparkLine",
	props: ["scoreLines"],
	data() {
		return {
			width: 40,
			height: 15,
			dataLines: []
		};
	},
	watch: {
		scoreLines: function () {
			this.loadData();
		}
	},
	mounted() {
		this.loadData();
	},
	methods: {
		loadData: async function () {
			this.dataLines = await this.addRunningTotals(this.scoreLines);
			this.drawScoreGraph();
		},

		drawScoreGraph: async function () {
			let allScores = [];
			this.dataLines.forEach((scoreLine) => {
				allScores = allScores.concat(scoreLine.scores);
			});
			const minDate = d3.min(allScores, score => new Date(score.date));
			const maxDate = d3.max(allScores, score => new Date(score.date));
			const minScore = Math.min(2, d3.min(allScores, score => score.runningScore)) - 3;
			const maxScore = Math.max(-2, d3.max(allScores, score => score.runningScore)) + 3;
			const x = d3.scaleTime().domain([minDate, maxDate]).range([0, this.width]);
			const y = d3.scaleLinear().domain([minScore, maxScore]).range([this.height, 0]);
			const line = d3.line()
				.x(d => x(new Date(d.date)))
				.y(d => y(d.runningScore))
				.curve(d3.curveBumpX);
			const span = d3.select(this.$refs.canvas);
			span.selectAll("*").remove();
			const chart = span.append("svg")
				.attr("width", this.width)
				.attr("height", this.height)
				.attr("transform", "translate(2,3)")
				.append("g")

			//Chart Lines
			chart.selectAll()
				.data(this.scoreLines).enter()
				.append('path')
				.attr('fill', 'none')
				.attr('stroke', d => d.color)
				.attr('stroke-width', 1)
				.attr('shape-rendering', 'crispEdges')
				.datum(d => d.scores)
				.attr('d', line)

			//Chart Circles
			chart.selectAll()
				.data(allScores).enter()
				.append('circle')
				.attr('r', '1')
				.attr('shape-rendering', 'crispEdges')
				.attr('fill', d => d.score < 0 ? "#ed7878" : "#00e600")
				.attr('stroke', "none")
				.attr('cx', d => x(new Date(d.date)))
				.attr('cy', d => y(d.runningScore))

		},

		addRunningTotals: async function (scoreLines) {
			scoreLines.forEach((scoreLine) => {
				let runningScore = 0, runningChoices = 0, runningCorrect = 0;
				if (scoreLine.scores && scoreLine.scores.length > 0) {
					scoreLine.scores.forEach((score) => {
						runningScore += +score.score;
						runningChoices += +score.numChoices;
						runningCorrect += +score.numCorrect;
						score.runningScore = runningScore;
						score.runningChoices = runningChoices;
						score.runningCorrect = runningCorrect;
					});
				}
			});
			return scoreLines;
		}
	}
}
</script>


<style scoped>
.axisWhite line {
	stroke: white;
}

.axisWhite path {
	stroke: white;
}

.axisWhite text {
	fill: white;
}

.sparkline {
	display: inline-block;
}
</style>
