<template>
	<div class="right" :class="{'big': size=='big', 'small': size=='small', 'good ': score >= 0, 'bad ': score < 0}">{{ score }} ({{ numCorrect }}/{{ numChoices }}) {{ percentage }}%</div>
</template>

<script>
export default {
	name: "Score",
	props: ["size", "score", "numCorrect", "numChoices", "percentage"]
}
</script>

<style scoped>
.big {
	font-size: 18px;
}
.small {
	font-size: 12px;
}
</style>
